import React from 'react';
import LinkAnimated from '../LinkAnimated';

interface SectionLinkProps {
  onClick: () => void;
  selected: boolean;
  name: string;
}

const SectionLink: React.FC<SectionLinkProps> = ({
  onClick,
  selected,
  name
}) => (
  <LinkAnimated onClick={onClick} selected={selected}>
    {name}
  </LinkAnimated>
);

export default SectionLink;
