import React from 'react';
import styled from 'styled-components';
import animationData from '../../../../static/json/loader.json';
import { fontSize } from '../../theme';
import LottieMemo from '../LottieMemo';

interface LoaderProps {
  loaded: boolean | undefined;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  renderer: 'svg',
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Loader: React.FC<LoaderProps> = ({ loaded }) => {

  const width = 100;
  const height = 100;

  if (loaded) return null;

  return (
    <Wrap>
      <LoaderContainer>
        <LoaderText>送信中...</LoaderText>
        <LottieMemo
          defaultOptions={defaultOptions}
          width={width}
          height={height}
          touched={false}
          segments={[]}
          ariaRole=''
        />
      </LoaderContainer>
    </Wrap>
  );
};

export default React.memo(Loader);

const Wrap = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  width: 100vw;
  min-height: 100vh;
  z-index: 1000;
`;

const LoaderContainer = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  transform: translateY(-50%);
`;

const LoaderText = styled.div`
  color: #fff;
  font-size: ${fontSize.small};
  margin-top: 4px;
  text-align: center;
`;