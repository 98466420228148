import * as React from 'react';
import styled, { css } from 'styled-components';
import * as HoC from '../../util/HoC';
import { userIcon, itemNoImage } from '../../../utils';
import { imageLoader } from './util';

interface InagePresenterProps extends ImageContainerProps {
  picUrl: string | ArrayBuffer;
  mode?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  alt?: string;
}

const ImagePresenter: React.FC<InagePresenterProps> = ({
  imgId,
  picUrl,
  isIcon,
  style,
  mode,
  width,
  height,
  maxHeight,
  alt,
  isBackground,
}) => {
  if (isBackground) {
    return (
      <WrapBackgroundDiv
        src={picUrl}
        alt={alt}
        height={height}
        maxHeight={maxHeight}
        width={width}
        mode={mode}
      />
    );
  }

  if (typeof picUrl === 'string' && picUrl.indexOf('.svg') !== -1) {
    return (
      <Picture>
        <Wrapimg
          key={imgId}
          id={imgId}
          src={picUrl}
          onError={(e: any) => {
            e.target.src = isIcon ? userIcon : itemNoImage;
          }}
          alt={alt}
          className={style}
          mode={mode}
          height={height}
          maxHeight={maxHeight}
          width={width}
        />
      </Picture>
    );
  }

  return (
    <Picture>
      <source type='image/webp' srcSet={`${picUrl}&p=t`} />
      <Wrapimg
        key={imgId}
        id={imgId}
        src={picUrl}
        onError={(e: any) => {
          e.target.src = isIcon ? userIcon : itemNoImage;
        }}
        alt={alt}
        className={style}
        mode={mode}
        height={height}
        maxHeight={maxHeight}
        width={width}
      />
    </Picture>
  );
};

const Picture: any = styled.picture`
  text-align: center;
`;

const WrapBackgroundDiv: any = styled.div`
  ${({ src, height, maxHeight, width }: any) => css`
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: 100%;
    width: ${width};
    max-height: ${maxHeight};
    height: ${height};
    border-radius: ${({ mode }: any) => (mode === 'roundcorner' ? '8px' : '')};
  `};
`;

const Wrapimg: any = styled.img`
  ${({ maxHeight }: any) => css`
    max-height: ${maxHeight};
  `};
  max-width: 100%;

  /* 角丸の場合 */
  border-radius: ${({ mode }: any) => (mode === 'roundcorner' ? '8px' : '')};
  /* サークルの場合 */
  border-radius: ${({ mode }: any) => (mode === 'circle' ? '50%' : '')};
  border: ${({ mode }: any) => (mode === 'circle' ? '1px solid #ddd' : '')};
  box-sizing: ${({ mode }: any) => (mode === 'circle' ? 'border-box' : '')};
`;

// 以下はデータの加工のみ
interface ImageContainerProps {
  src: string;
  imgId: string;
  style: string;
  type: string;
  isIcon: boolean;
  isBackground: boolean;
}

const ImageContainer: React.SFC<ImageContainerProps & HoC.ContainerProps> = (
  props
) => {
  const { src, presenter } = props;

  let picUrl = imageLoader({ src, width: 1260 });
  let isNoImg = false;

  return presenter({ picUrl, isNoImg, ...props });
};

export const Image = HoC.containPresenter(ImageContainer, ImagePresenter);
