import List from '../../atoms/List';
import React from 'react';
import styled from 'styled-components';

import { fontSize, breakpoint, chevronStyle } from '../../theme';
import {
  InstagramIcon,
  TwitterIcon,
  FacebookIcon,
  SquareLogo,
  ShopURL,
  Tel,
  HrefTel,
} from '../../../utils';
import LazyLoadImage from '../../atoms/LazyLoadImage';
import Anchor from '../../atoms/Anchor';
import { LinksType } from '../Header';

const links = [
  { name: 'HOME', to: '' },
  { name: '会社情報', to: 'company' },
  { name: 'ニュース', to: 'news' },
  { name: '採用', to: 'recruit' },
  { name: 'お問い合わせ', to: 'contact' },
];

const linksService = [
  { name: 'ハンドメイドSNS croccha', to: 'croccha' },
  { name: 'ハンドメイド材料のお店 croccha shop', to: ShopURL },
  { name: 'SDGs × ハンドメイド', to: 'sdgs-handmade' },
  { name: 'IT・DX支援', to: 'it-support' },
];

const linksBottom = [
  { name: 'プライバシーポリシー', to: 'privacypolicy' },
  { name: '利用規約', to: 'terms-of-use' },
  { name: '特定商取引法に基づく表示', to: 'commercial-transactions' },
];
const snsLinks = [
  {
    bgUrl: InstagramIcon,
    to: 'https://www.instagram.com/croccha/?hl=ja',
  },
  {
    bgUrl: TwitterIcon,
    to: 'https://twitter.com/croccha_com',
  },
  {
    bgUrl: FacebookIcon,
    to: 'https://www.facebook.com/croccha.jp',
  },
];

const Footer = () => (
  <FooterContainer>
    <div>
      {/* ナビゲーション */}
      <SiteMap
        dataSource={links}
        renderRow={(linkObj: LinksType, index) => (
          <li key={index}>
            <Anchor url={linkObj.to}>
              <SitemapLink>{linkObj.name}</SitemapLink>
            </Anchor>
          </li>
        )}
        empty={null}
      />

      <FooterBottom>
        <div>
          {/* 他リンク */}
          <FooterLinkList
            dataSource={linksService}
            renderRow={(linkObj: LinksType, index) => (
              <li key={index}>
                <Anchor url={linkObj.to}>
                  <FooterLink>{linkObj.name}</FooterLink>
                </Anchor>
              </li>
            )}
            empty={null}
          />

          {/* 他リンク */}
          <FooterLinkList
            dataSource={linksBottom}
            renderRow={(linkObj: LinksType, index) => (
              <li key={index}>
                <Anchor url={linkObj.to}>
                  <FooterLink>{linkObj.name}</FooterLink>
                </Anchor>
              </li>
            )}
            empty={null}
          />
        </div>

        {/* 会社情報 */}
        <CompanyInfo>
          <Anchor url='/'>
            <LogoWrap>
              <Logo src={SquareLogo} width='100px' alt='tryangle' />
            </LogoWrap>
          </Anchor>
          <div>
            <div>本店</div>
            <div>大阪府東大阪市桜町2-3 日興桜町ビル4F</div>
            <div>
              <a href={HrefTel}>Tel : { Tel }</a>
            </div>
            <AddressBottom>
              <a href='m&#97;i&#108;t&#111;:inf&#111;@cr&#111;&#99;&#99;&#104;a.&#106;p'>
                inf&#111;@cr&#111;&#99;&#99;&#104;a.&#106;p
              </a>
            </AddressBottom>
            {/* ソーシャルリンク */}
            <SnsList
              dataSource={snsLinks}
              renderRow={(linkObj, index) => (
                <li key={index}>
                  <Anchor url={linkObj.to}>
                    <SnsIcon bgUrl={linkObj.bgUrl} />
                  </Anchor>
                </li>
              )}
              empty={null}
            />
          </div>
        </CompanyInfo>
      </FooterBottom>
    </div>

    <Copyright>
      Copyright © 2021 tryangle Co., Ltd. All Rights Reserved.
    </Copyright>
  </FooterContainer>
);

export default Footer;

const FooterContainer = styled.footer`
  background: #1f1f1e;
  color: #fff;
  margin-top: auto;

  > div {
    @media ${breakpoint.sp} {
      padding: 40px 1rem;
    }
    @media ${breakpoint.tab} {
      margin: 0 auto;
      max-width: 1024px;
      padding: 1rem 70px;
    }
  }
`;

const SiteMap = styled(List)`
  margin-bottom: 3rem;
  font-size: 15px;
  font-weight: bold;

  > li {
    @media ${breakpoint.sp} {
      margin-bottom: 1rem;
    }

    @media ${breakpoint.tab} {
      display: inline-block;

      &:after {
        content: '|';
        margin: 0 1rem;
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
`;

const SitemapLink = styled.span`
  color: #fff;
`;

const FooterBottom = styled.div`
  @media ${breakpoint.tab} {
    display: flex;
    justify-content: space-between;
  }
`;

const FooterLinkList = styled(List)`
  margin-bottom: 1rem;
  @media ${breakpoint.sp} {
    margin-bottom: 4rem;
  }
`;

const FooterLink = styled.span`
  color: #fff;
  font-size: 13px;
  &::before {
    ${chevronStyle};
    transform: rotate(45deg); /* chevron top */
  }
`;

const CompanyInfo = styled.div`
  @media ${breakpoint.sp} {
    margin-bottom: 1rem;
  }
  @media ${breakpoint.tab} {
    display: flex;
  }
  font-size: 13px;
  a {
    color: #fff;
  }
`;

const LogoWrap = styled.div`
  @media ${breakpoint.sp} {
    margin-bottom: 2rem;
    margin-right: auto;
  }
`;

const Logo = styled(LazyLoadImage)`
  margin-right: 2rem;
`;

const SnsList = styled(List)`
  margin-top: 20px;

  > li {
    display: inline-block;
    margin-right: 1rem;
  }

  @media ${breakpoint.sp} {
    margin: 80px auto -40px;
    text-align: center;

    > li:last-child {
      margin-right: 0;
    }
  }
`;

const SnsIcon: any = styled.div`
  display: inline-block;
  background: url(${(props: any) => props.bgUrl});
  background-repeat: no-repeat;
  background-size: 27px;
  width: 27px;
  height: 27px;
`;

/* コピーライト */
const Copyright = styled.div`
  max-width: 1024px;
  margin: auto;
  padding: 0 70px 1.5rem;
  font-size: ${fontSize.small};
  text-align: center;
`;

const AddressBottom = styled.div`
  margin-bottom: 1rem;
`;
