import {
  CROCCHA_API,
  HTTPS_PROTOCOL,
  HTTP_PROTOCOL,
  itemNoImage,
} from 'app/src/utils';

export type ImageFieldImgSrc = string | ImageFieldData;
export type ImageFieldData = {
  imagefile: Blob;
  croppedImgData: DataURL;
};

// e.g. data:image/png;base64,iVB...
export type DataURL = string;

type ImageUrl =
  | { imgType: 'newImg'; picUrl: ImageFieldData }
  | { imgType: 's3Img'; picUrl: string }
  | { imgType: 'noImg'; picUrl: typeof itemNoImage };

export const makeImageUrl = (
  picUrl: string | ImageFieldData | undefined | null
): ImageUrl => {
  const img = separateByImgType(picUrl);

  if (img.imgType === 'noImg' || img.imgType === 'newImg') {
    return img;
  }

  return {
    picUrl: makePicUrl(img.picUrl).picUrl,
    imgType: 's3Img',
  };
};

/**
 * picUrlからimgTypeごとにわける
 */
export const separateByImgType = (
  picUrl: string | ImageFieldData | undefined | null
): ImageUrl => {
  if (picUrl == null) {
    return { picUrl: itemNoImage, imgType: 'noImg' };
  }

  if (isImageFieldData(picUrl)) {
    // data:image or file:/
    return { picUrl, imgType: 'newImg' };
  }

  if (picUrl === '' || picUrl.startsWith('no-image')) {
    return { picUrl: itemNoImage, imgType: 'noImg' };
  }

  return { picUrl, imgType: 's3Img' };
};

const isImageFieldData = (
  postPic: ImageFieldImgSrc
): postPic is ImageFieldData => postPic.hasOwnProperty('croppedImgData');

const imgsOfExternalSite = ['thumbnail.image.rakuten.co.jp'];

const isImgOfExternalSite = (picUrl: string) =>
  imgsOfExternalSite.some((d) => picUrl.indexOf(d) !== -1);

/**
 * 存在する画像のurlを整形し、種類を判別する
 * その際に、queryなどは付与しない
 */
type ImgType =
  | 'noImg'
  | 'contentful'
  | 'withWidthContentful'
  | 's3'
  | 'withWidth'
  | 'external';

export const makePicUrl = (
  picUrl: string
): { picUrl: string; type: ImgType } => {
  const startsWith = (word: string) => picUrl.startsWith(word);

  switch (true) {
    case picUrl.indexOf('.svg') !== -1:
      return { picUrl, type: 'external' };

    case startsWith('/static/'):
      return { picUrl, type: 'external' };

    // Contentfulの画像のresize
    case startsWith('https://images.ctfassets.net/'):
      // 最初からwidthの指定がある場合はそちらを優先する
      if (picUrl.indexOf('?w=') !== -1) {
        return { picUrl, type: 'withWidthContentful' };
      }
      return { picUrl, type: 'contentful' };

    // 外部サイト (e.g. 楽天の商品画像)
    case startsWith(HTTP_PROTOCOL):
    case isImgOfExternalSite(picUrl):
      return { picUrl, type: 'external' };

    case startsWith(CROCCHA_API.STATIC_HOST_OLD):
      return {
        picUrl: picUrl.replace(
          CROCCHA_API.STATIC_HOST_OLD,
          CROCCHA_API.STATIC_HOST
        ),
        type: 's3',
      };

    case startsWith(CROCCHA_API.STATIC_HOST):
      // 最初からwidthの指定がある場合はそちらを優先する
      if (picUrl.indexOf('?w=') !== -1) {
        return { picUrl, type: 'withWidth' };
      }
      return { picUrl, type: 's3' };

    case picUrl.indexOf(HTTPS_PROTOCOL) === -1:
      // `/posts/`などの形式;
      const p = `${CROCCHA_API.STATIC_HOST}${picUrl}`;

      // 最初からwidthの指定がある場合はそちらを優先する
      if (picUrl.indexOf('?w=') !== -1) {
        return { picUrl: p, type: 'withWidth' };
      }

      return { picUrl: p, type: 's3' };

    default:
      return { picUrl, type: 'external' };
  }
};

/**
 * ImgTypeごとにqueryを付与する
 */
export const imageLoader = ({ src, width, quality = 75 }: any): string => {
  const { type, picUrl } = makePicUrl(src);

  if (type === 'external') {
    return picUrl;
  }

  if (type === 'contentful') {
    return picUrl + `?w=${width}` + `&q=${quality}` + `&fm=webp`;
  }

  if (type === 'withWidthContentful') {
    return picUrl + `&q=${quality}` + `&fm=webp`;
  }

  if (type === 'withWidth') {
    return picUrl + ``;
  }

  return picUrl + `?w=${width}`;

  // if (type === 'withWidth') {
  //   return picUrl + `&p=t`;
  // }

  // return picUrl + `?w=${width}&p=t`;
};
