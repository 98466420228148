import React, { memo } from 'react';
import Lottie, { LottieOptions } from 'lottie-react-web';

type Props = {
  touched: boolean;
  segments: number[];
  width: number;
  height: number;
  defaultOptions: LottieOptions;
  ariaRole?: string;
};

// eslint-disable-next-line react/display-name
const LottieMemo: React.FC<Props> = memo(
  ({ segments, width, height, defaultOptions, ariaRole }) => {
    return (
      <Lottie
        options={defaultOptions}
        width={width}
        height={height}
        segments={segments}
        forceSegments
        ariaRole={ariaRole}
      />
    );
  },
  (p, n) => {
    // まだ1回も触っていない時は、segmentsのframeを反映させる必要があるので際レンダリングさせる
    if (!p.touched || !n.touched) return false;
    // segmentsのframeを見て際レンダリングするかどうか決める
    return p.segments[0] == n.segments[0] && p.touched == n.touched;
  }
);

export default LottieMemo;
