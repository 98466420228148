export const LOCAL_API = 'localHost';
export const HOST_STAGING_API = 'stagingAPIGateway';
export const HOST_PROD_API = 'prodAPIGateway';
export const HOST_STAGING_ALB_API = 'stagingALB';
export const HOST_PROD_ALB_API = 'prodALB';

// 写真のアップロードに使用中
export const ALB_LOCAL = 'http://localhost:3000';
export const ALB_STAGING = 'https://dev.alb.app.croccha.com:3000';
export const ALB_PROD = 'https://alb.app.croccha.com:3000';

export const HTTP_PROTOCOL = 'http://';
export const HTTPS_PROTOCOL = 'https://';
export const ORIGIN = [
  'http://localhost:3000/',
  'http://localhost:3001/',
  'https://dev.web.croccha.com/',
  'https://web.croccha.com/',
];

const ConfigProd = {
  NCMB: {
    clientKey:
      'ae084afd2d015c76d1b2bb9d5021f3c6ca2e1b53a237532b2e4b7e1e69771b4d',
    secretKey:
      '35192af0565e4b0bf71e7ab4a74e46c6e517f52c18e39fba9db8b02eca06446d',
    FCMSenderId: '481310049067',
  },
  GoogleAnalytics: {
    id: 'UA-98982894-4',
  },
  Slack: {
    url: 'https://slack.com/api/chat.postMessage',
    token:
      'xoxp-213412033284-213339416083-394522030050-c140f2632fbe916012ebfb38bb461222',
  },
};

const ConfigStaging = {
  NCMB: {
    // 変更する必要あり
    clientKey:
      'fb3b0d3db796eabeef62bcdff473ba136e24a398e483ca4e824b2d13f0e1208a',
    secretKey:
      'c4455b160b55652609d8788ddb8d85fd7e6e47c89f4dc872a44f175be70613c7',
    FCMSenderId: '481310049067',
  },
  GoogleAnalytics: {
    id: 'UA-98982894-12',
  },
  Slack: {
    url: 'https://slack.com/api/chat.postMessage',
    token:
      'xoxp-213412033284-213339416083-394522030050-c140f2632fbe916012ebfb38bb461222',
  },
};

/* APサーバ(node.js)のホスト  */
/* ローカル  */
// export const CROCCHA_API = {
//   AP_HOST: LOCAL_API,
//   AP_LAMBDA_HOST: LOCAL_API,
//   ALB_HOST: ALB_LOCAL,
//   CONFIG: ConfigStaging,
//   S3_HOST: 'staging',
//   STATIC_HOST: 'https://static.croccha.com',
//   STATIC_HOST_OLD: 'https://static.mano-handmade.com',
//   SHOP: 'https://dev.shop.croccha.com/tryangle/authentication/',
//   SHOP_TOP: 'https://dev.shop.croccha.com',
//   SHOP_SEARCH:
//     'https://dev.shop.croccha.com/tryangle/authentication/stp/product/search/typeA1/',
//   MASE_HOST: 'maseAPI',
//   ASSETS: 'https://static.croccha.com/company/',
//   SERVICEWORKER: 'http://localhost:5000/service-worker.js',
//   GA_TRACKING_CODE: '',
//   AMPLITUDE_API_KEY: 'stgApiKey',
//   SELF_DOMAIN: 'localhost:8000',
//   SERVER_LESS_COMPANY_API: 'http://localhost:4000/stg'
// };

// /* 開発環境  */
// export const CROCCHA_API = {
//   AP_HOST: HOST_STAGING_ALB_API,
//   AP_LAMBDA_HOST: HOST_STAGING_API,
//   ALB_HOST: ALB_STAGING,
//   CONFIG: ConfigStaging,
//   S3_HOST: 'production',
//   STATIC_HOST: 'https://static.croccha.com',
//   STATIC_HOST_OLD: 'https://static.mano-handmade.com',
//   SHOP: 'https://shop.croccha.com/tryangle/authentication/',
//   SHOP_TOP: 'https://dev.shop.croccha.com',
//   SHOP_SEARCH:
//     'https://dev.shop.croccha.com/tryangle/authentication/stp/product/search/typeA1/',
//   MASE_HOST: 'maseAPI',
//   ASSETS: 'https://static.croccha.com/company/',
//   SERVICEWORKER: 'https://dev.web.croccha.com/service-worker.js',
//   GA_TRACKING_CODE: 'UA-98982894-10',
//   AMPLITUDE_API_KEY: 'stgApiKey',
//   SELF_DOMAIN: 'http://stg-company-hp.s3-website-ap-northeast-1.amazonaws.com',
//   SERVER_LESS_COMPANY_API:
//     'https://zq7abdqzlc.execute-api.ap-northeast-1.amazonaws.com/stg',
// };

/* 本番環境  */
export const CROCCHA_API = {
  AP_HOST: HOST_PROD_ALB_API,
  AP_LAMBDA_HOST: HOST_PROD_API,
  ALB_HOST: ALB_PROD,
  CONFIG: ConfigProd,
  S3_HOST: 'production',
  STATIC_HOST: 'https://static.croccha.com',
  STATIC_HOST_OLD: 'https://static.mano-handmade.com',
  SHOP: 'https://shop.croccha.com/tryangle/authentication/',
  SHOP_TOP: 'https://shop.croccha.com',
  SHOP_SEARCH:
    'https://shop.croccha.com/tryangle/authentication/stp/product/search/typeA1/',
  MASE_HOST: 'maseAPI',
  ASSETS: 'https://static.croccha.com/company/',
  SERVICEWORKER: 'https://web.croccha.com/service-worker.js',
  GA_TRACKING_CODE: 'UA-98982894-11',
  AMPLITUDE_API_KEY: 'prodApiKey',
  SELF_DOMAIN: 'https://try.croccha.com',
  SERVER_LESS_COMPANY_API:
    'https://qfzu5lkdhb.execute-api.ap-northeast-1.amazonaws.com/prod',
};

/**
 * デバッグ用の変数
 */
export const isProduction = CROCCHA_API.AP_HOST === HOST_PROD_ALB_API;

/**
 * 画像のURLはここで管理する
 */
export const itemNoImage = `https://static.croccha.com/webapp/banners/jpg/item-no-image.jpg`;
export const userIcon = `${CROCCHA_API.ASSETS}mypage-thumbnail.svg`;

// 会社HP
// favicon
export const FavIcon = `${CROCCHA_API.ASSETS}favicon.ico`;
// touch icon
export const LogoIcon = `${CROCCHA_API.ASSETS}/logo/logo-icon.jpg`;
// lgoo
export const InlineLogo = `${CROCCHA_API.ASSETS}logo/logo.svg`;
export const SquareLogo = `${CROCCHA_API.ASSETS}logo/tr_logo.svg`;

// 各ページのヘッダ画像
export const PhilosophyPc = `${CROCCHA_API.ASSETS}page/philosophy_pc_.png?w=1100`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const PhilosophySp = `${CROCCHA_API.ASSETS}page/philosophy_sp_.png`;
export const CompanyPc = `${CROCCHA_API.ASSETS}page/company_pc_.png?w=1200`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const CompanySp = `${CROCCHA_API.ASSETS}page/company_sp_.png`;
export const NewsPc = `${CROCCHA_API.ASSETS}page/news_pc_.png?w=1200`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const NewsSp = `${CROCCHA_API.ASSETS}page/news_sp_.png`;

// hero
export const HeroImg = `${CROCCHA_API.ASSETS}hero.jpg`;
// アイコン
export const MenuIcon = `${CROCCHA_API.ASSETS}icon/icon-menu.svg`;
export const InstagramIcon = `${CROCCHA_API.ASSETS}icon/icon-instagram.svg`;
export const FacebookIcon = `${CROCCHA_API.ASSETS}icon/icon-facebook-square.svg`;
export const TwitterIcon = `${CROCCHA_API.ASSETS}icon/icon-twitter-square.svg`;
export const SymbolIcon = `${CROCCHA_API.ASSETS}icon/Symbol.svg`;

// product紹介
export const CrocchaLogo = `${CROCCHA_API.ASSETS}product/croccha_logo_w.png`;
export const Mockup = `${CROCCHA_API.ASSETS}product/mockup_fix_.png?w=500`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const ServiceBg = `${CROCCHA_API.ASSETS}product/service_bg.png`;
export const BgProduct = `${CROCCHA_API.ASSETS}product/bg_product.png`;
export const BgProductSp = `${CROCCHA_API.ASSETS}product/service_Bg_SP.png`;
export const AppleStore = `${CROCCHA_API.ASSETS}product/app_store.png`;
export const GooglePlay = `${CROCCHA_API.ASSETS}product/google_play.png`;

// IT・DX支援
export const Support1_1Img = `https://static.croccha.com/company/v2/itsupport1-1.jpg`;
export const Support1_2Img = `https://static.croccha.com/company/v2/itsupport1-2.jpg`;
export const Support2_1Img = `https://static.croccha.com/company/v2/itsupport2-1.jpg`;
export const Support2_2Img = `https://static.croccha.com/company/v2/itsupport2-2.jpg`;
export const Support2_3Img = `https://static.croccha.com/company/v2/itsupport2-3.jpg`;
export const Support3_1Img = `https://static.croccha.com/company/v2/itsupport3-1.jpg`;
export const Support3_2Img = `https://static.croccha.com/company/v2/itsupport3-2.jpg`;
export const BGImg = `https://static.croccha.com/company/v2/company_sp_2.jpg`;
export const DXSupportMainImg = `https://static.croccha.com/company/v2/it_dx_support_main.jpg`;
export const CrocchaImg = `https://static.croccha.com/company/v2/service_croccha.png?w=640`;
export const Img01 = `https://static.croccha.com/company/v2/croccha_img01.png`;
export const Img02 = `https://static.croccha.com/company/v2/croccha_img02.png`;
export const Img03 = `https://static.croccha.com/company/v2/croccha_img03.png`;
export const Img04 = `https://static.croccha.com/company/v2/croccha_img04.png`;
export const Img05 = `https://static.croccha.com/company/v2/croccha_img05.png`;
export const LogoGroup = `https://static.croccha.com/company/v2/logogroup.png`;
export const LogoGroupSP = `https://static.croccha.com/company/v2/logogroup_sp.png`;
export const BgImg01 = `https://static.croccha.com/company/v2/bg-image01.png`;
export const BgImg02 = `https://static.croccha.com/company/v2/bg-image02.png`;
export const MembersAllImg = `https://static.croccha.com/company/v2/members_all.jpg?w=800`;

// SDGsハンドメイド
export const SDGsTop = `https://static.croccha.com/company/v2/sdgs-handmade/top_sdgs_handmade.png`;
export const SDGs01 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs01.png`;
export const SDGs02 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs02.png`;
export const SDGs03 = `https://static.croccha.com/company/v2/sdgs-handmade/logo_sdgs.png`;
export const SDGs04 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs04.png`;
export const SDGs05 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs05.png`;
export const SDGs06 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs06.png`;
export const SDGs07 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs07.png`;
export const SDGs08 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs08.jpg`;
export const SDGs09 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs09.jpg`;
export const SDGs10 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs10.jpg`;
export const SDGs11 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs11.png`;
export const SDGs12 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs12.jpg`;
export const SDGs13 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs13.jpg`;
export const SDGs14 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs14.png`;
export const SDGs15 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs15.png`;
export const SDGs16 = `https://static.croccha.com/company/v2/sdgs-handmade/lp_sdgs16.png`;

export const MetaDesc = `ハンドメイドSNSアプリ「croccha」やIT支援事業、動画制作などを行なっているtryangle（トライアングル）株式会社の公式サイトです。`;
export const MetaOGP = `https://tryangle-croccha.s3-ap-northeast-1.amazonaws.com/company/ogp/ogp4.jpg`;
export const DougaParlorImgMeta =
  'https://tryangle-croccha.s3.ap-northeast-1.amazonaws.com/company/ec-movie/ec-movie-ogp_02.png';

export const SFujiwaraImg = `https://static.croccha.com/company/v2/sfujiwara.png`;

export const ServiceCrocchaShop = `https://static.croccha.com/company/v2/service_shop02.jpeg`;
export const ShopURL = `https://shop.croccha.com`;

//電話番号
export const Tel = `072-920-4510`;
export const HrefTel = `tel:0729204510`;