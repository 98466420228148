import * as React from 'react';
import styled from 'styled-components';

type ListProps<T> = {
  renderRow: (row: T, index: number) => void;
  dataSource: T[];
  empty: React.ReactChild;
  className?: string;
};

type ListFC = <T extends any>(
  p: ListProps<T>
) => React.ReactElement<ListProps<T>>;

const List: ListFC = ({ renderRow, dataSource, empty, className }) => {
  if (dataSource == null) return <Empty>{empty}</Empty>;
  const existsSource = dataSource.length > 0;
  return (
    <ul className={className}>
      {existsSource ? (
        dataSource.map((row, index) => renderRow(row, index))
      ) : (
        <Empty>{empty}</Empty>
      )}
    </ul>
  );
};

export default List;

const Empty = styled.div`
  width: 100%;
`;
