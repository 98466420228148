import Headroom from 'react-headroom'; // スクロールでヘッダーを隠してくれる
import SectionLink from '../../atoms/SectionLink';
import { Image } from '../../atoms/Image';
import List from '../../atoms/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core';
import Anchor from '../../atoms/Anchor';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  borderColor,
  breakpoint,
  mainColor,
  ModalCloseButtonStyle,
} from '../../theme';
import { InlineLogo, MenuIcon, ShopURL } from '../../../utils';
import useMedia from '../../../customHooks/userMedia';
import Popover from '@material-ui/core/Popover';

// react-scroll-sectionの詳細
// https://react-scroll-section.netlify.com/docs-getting-started

export type LinksType = {
  name: string;
  to: string;
};

const links: LinksType[] = [
  { name: 'サービス', to: 'services' },
  { name: '会社情報', to: 'company' },
  { name: 'ニュース', to: 'news' },
  { name: '採用', to: 'recruit' },
  { name: 'お問い合わせ', to: 'contact' },
];

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpenDrawer(open);
    };

  const isMobile = useMedia('(max-width: 767px)');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <StyledHeader>
      <Headroom
        style={{
          borderBottom: '1px solid ' + borderColor,
          background: '#fff',
        }}
      >
        <Content>
          <LogoImageWrap url='/'>
            <Image src={InlineLogo} alt='tryangle' onClick={null} />
          </LogoImageWrap>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ServiceWrap>
              <ServiceName>
                <Anchor url='croccha'>ハンドメイドSNS croccha</Anchor>
              </ServiceName>
              <ServiceName>
                <Anchor url='croccha'>ハンドメイド材料のお店 croccha shop</Anchor>
              </ServiceName>
              <ServiceName>
                <Anchor url='sdgs-handmade'>SDGs × ハンドメイド</Anchor>
              </ServiceName>
              <ServiceName>
                <Anchor url='it-support'>IT・DX支援</Anchor>
              </ServiceName>
            </ServiceWrap>
          </Popover>

          {/* PCの時のみ表示する */}
          {!isMobile && (
            <div>
              <Nav>
                <LinkList
                  dataSource={links}
                  renderRow={(linkObj: LinksType, index) => {
                    if (linkObj.to === 'services') {
                      return (
                        <li key={index}>
                          <div onClick={handleClick}>
                            <SectionLink
                              key={index}
                              onClick={null}
                              selected={false}
                              name={linkObj.name}
                            />
                          </div>
                        </li>
                      );
                    }

                    return (
                      <li key={index}>
                        <Anchor url={linkObj.to}>
                          <SectionLink
                            key={index}
                            onClick={null}
                            selected={false}
                            name={linkObj.name}
                          />
                        </Anchor>
                      </li>
                    );
                  }}
                  empty={null}
                />
              </Nav>
            </div>
          )}

          {/* スマホの時のみDrawerメニューを表示する */}
          {isMobile && (
            <FlexWrap>
              <IconButton onClick={toggleDrawer(true)} />

              <StyledDrawer
                anchor='right'
                open={openDrawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                <DrawerWrap
                  role='presentation'
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <CloseButton onClick={toggleDrawer(false)} />

                  <List
                    dataSource={links}
                    renderRow={(linkObj: LinksType, index) => {
                      if (linkObj.to === 'services') {
                        return (
                          <>
                            <li key={index}>
                              <StyledMenuAnchor url={'croccha'}>
                                ハンドメイドSNS croccha
                              </StyledMenuAnchor>
                            </li>
                            <li key={index}>
                              <StyledMenuAnchor url={ShopURL}>
                                croccha shop
                              </StyledMenuAnchor>
                            </li>
                            <li key={index}>
                              <StyledMenuAnchor url={'sdgs-handmade'}>
                                SDGs × ハンドメイド
                              </StyledMenuAnchor>
                            </li>
                            <li key={index}>
                              <StyledMenuAnchor url={'it-support'}>
                                IT・DX支援
                              </StyledMenuAnchor>
                            </li>
                          </>
                        );
                      }

                      return (
                        <li key={index}>
                          <StyledMenuAnchor url={linkObj.to}>
                            {linkObj.name}
                          </StyledMenuAnchor>
                        </li>
                      );
                    }}
                    empty={null}
                  />
                </DrawerWrap>
              </StyledDrawer>
            </FlexWrap>
          )}
        </Content>
      </Headroom>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  /* headroom分がコンテンツ分の高さを取ってくれない */
  min-height: 70px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0.5rem 70px;
  align-items: flex-end;
  @media ${breakpoint.sp} {
    padding: 6px 15px 9px;
  }
  @media ${breakpoint.tabOnly} {
    padding: 12px 32px;
  }
`;

const LogoImageWrap = styled(Anchor)`
  width: 180px;

  @media ${breakpoint.sp} {
    width: 130px;
  }
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const PhonePc = styled.p`
  text-align: right;
  margin-left: 8px;
  font-weight: bold;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-weight: bold;

  @media ${breakpoint.sp} {
    display: none;
  }
`;

const LinkList = styled(List)`
  > li {
    display: inline-block;
    margin-left: 2rem;
  }
`;

const IconButton = styled.button`
  display: inline-block;
  background: url(${MenuIcon});
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
`;

// Drawerのスタイル
// Material UIへのスタイル指定
const StyledDrawer = withStyles({
  paper: {
    background: mainColor,
  },
})(SwipeableDrawer);

const DrawerWrap = styled.div`
  position: relative;
  padding-top: 48px;
  width: 76vw;
`;

const CloseButton = styled.button`
  ${ModalCloseButtonStyle};
  position: absolute;
  top: 28px;
  right: 18px;
`;

const StyledMenuAnchor = styled(Anchor)`
  display: block;
  padding: 0 26px;
  color: #fff;
  font-weight: bold;
  line-height: 56px;
`;

const ServiceWrap = styled.div`
  margin: 10px;
`;

const ServiceName = styled.div`
  margin: 10px;
  line-height: 30px;
  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
`;
